// 应用路由
const routers = [
  {
    path: "logList",
    name: "日志中心",
    meta: { hidden: false },
    component: () => import("@/views/loglist.vue"),
    // children: [
    //   {
    //     path: "demo",
    //     name: "demo",
    //     meta: { hidden: true },
    //     component: () => import("@/views/demo.vue"),
    //   },
    // ],
  },
];
export default routers;
